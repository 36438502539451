export const formatPrice = (value) => {
    if (typeof value === 'number') {
        return `${value.toFixed(2).replace(".", ",")} €`
    }

    return `${parsePrice(value).toFixed(2).replace(".", ",")} €`
}

export const formatPricePerThousand = (value) => {
    if (typeof value === 'number') {
        return value*100;
    }

    return parsePrice(value) * 100
}

export const parsePrice = (value) => {
    if (typeof value === 'number') {
        return value
    }

    return parseFloat(value.replace(",", "."))
}
