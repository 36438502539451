import React, { useCallback, useMemo } from "react";
import { useState, useEffect } from "react";
import  ArrowBack from "@material-ui/icons/ArrowBack";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";
import axios from "axios";
import { SERVICE_URL } from "..";
import { formatPrice } from "../utils/prices";


export function ProductDetailView(props) {

    const navigate = useNavigate();
    const token = props.token;

    const { state } = useLocation();
    const [searchParams] = useSearchParams()

    const carrello = state?.carrello || []

    const [articolo, setArticolo] = useState(undefined)
    const [qty, setQty] = useState(1);
    const [isLoading, setIsLoading] = useState(true)

    const productId = useMemo(() => searchParams.get('id'), [searchParams])

    const fetchProduct = useCallback(async () => {
        try {
            if(!token){
                navigate('/', { replace: true });
                return;
            }
            setIsLoading(true)
            const response = await axios.get(`${SERVICE_URL}/products/${productId}`, {
                headers: {
                    'Authorization' : `Bearer ${token}`
                }
            })
            setArticolo(response.data)
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [token, productId])

    useEffect(() => {
        if (productId) {
            fetchProduct()
        }
        else {
            navigate('/', { replace: true })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchProduct])


    function getPorzioni(){
        let porzioni = "";
        if(articolo["NPORZ"] && articolo["NPORZ"] !== ""){
            porzioni = articolo["NPORZ"] + " porz. / ";
            if(articolo["GRPORZ"] && articolo["GRPORZ"] !== ""){
                porzioni = porzioni + articolo["GRPORZ"] + " g";
            } else if(articolo["MGPORZ"] && articolo["MGPORZ"] !== ""){
                porzioni = porzioni + articolo["MGPORZ"] + " mg"
            }
        }
        return porzioni;
    }

    useEffect(() => {
        window.scrollTo({top:0, behavior: 'instant'});
    }, [])

    function goBack(){

        navigate("/", {
            replace: true,
            state: {
                carrello: carrello,
                searchParams: state?.searchParams
            }
        });
    }

    function removeQty(){
        if(qty > 1){
            let tmpQty = qty-1;
            setQty(tmpQty);
        }
    }

    function addQty(){
        let tmpQty = qty+1;
        setQty(tmpQty);
    }

    function addToCart(){
        updateCarrello();
    }

    function getValoreEnergetico(){
        if(articolo.KJ !== "" && articolo.KCAL !== ""){
            return articolo.KJ + " kJ / " + articolo.KCAL + " kcal";
        } else if(articolo.KJ === "" && articolo.KCAL !== ""){
            return articolo.KCAL + " kcal";
        } else if(articolo.KJ !== "" && articolo.KCAL === ""){
            return articolo.KJ + " kJ";
        } else {
            return "-";
        }
    }

    function updateCarrello(){
        let tmpArticles = [...carrello];
        if(qty === 0 ){
            tmpArticles = carrello.filter((item) => item.CODICE !== articolo.CODICE);

        } else {
            let found = false;
            carrello.forEach((elem,index) => {
                if(elem.CODICE === articolo.CODICE ){
                    tmpArticles[index].qty = tmpArticles[index].qty + qty;

                    found = true;
                }
            });
            if(!found){
                if(qty > 0 ){
                    let tmpArticle = articolo;
                    tmpArticle.qty = qty;
                    tmpArticles.push(tmpArticle);
                }
            }

        }
        navigate("/", {
            replace: true,
            state: {
                carrello: tmpArticles
            }
        });
    }

    return (
        <div
            className="container"
            style={{
                marginTop: "0px",
                backgroundColor: "#FFFFFF",
                position: "relative",

            }}
        >
            {isLoading &&
                <div style={{
                    display:`${isLoading ? "flex" : "none"}`,
                    justifyContent:"center",
                    alignItems:"start",
                    height: "100vh",
                    width: "100%",
                    position: "absolute",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    zIndex: "100"
                }}
                >
                    <dotlottie-player src="https://lottie.host/90a4cd86-6db1-4a26-a699-a36fd63d1fce/tEBIPFUn7o.json" mode="normal" background="transparent" speed="1" style={{width: "300px", height: "300px", display: "block"}} direction="1" loop autoplay></dotlottie-player>
                </div>
            }
            {articolo && <>
                <div

                style={{
                    position: 'sticky',
                    top: 0,
                    padding: "0px 20px",
                    backgroundColor: "#FFFFFF",
                    height: "120px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 0px"
                }}
            >
                <div
                    onClick={goBack}

                    style={{
                        marginTop: "8px"
                    }}
                >
                    <ArrowBack
                        style={{
                            width: "32px",
                            height: "32px",
                            color: "#0F4651"
                        }}
                    />
                </div>
                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize:"14px",
                        color: "#68ABAD",
                        display: "inline-block",

                        marginBottom: "0px",
                    }}
                >
                    {articolo.CATEGORIA}
                </p>
            </div>
            <div
                style={{
                    textAlign: "center"
                }}
            >
                <div
                    style={{
                        marginTop: "2px",
                        padding: "0px 20px",
                    }}
                >
                    <img
                        src={require(`../images/best-sellers/${articolo.CODICE}.png`)}
                        alt=''
                        style={{

                            height:"200px",
                            display: "inline-block"
                        }}
                    />
                </div>
                <div
                    style={{
                        textAlign: "left",
                        marginTop: "12px",
                        padding: "0px 20px",
                    }}
                >
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize:"26px",
                            color: "#0F4651",
                            display: "inline-block",
                            width: "100%",
                            marginBottom: "0px",
                        }}
                    >
                        {articolo.NOME}
                    </p>
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize:"18px",
                            color: "#0F4651",
                            display: "inline-block",
                            width: "100%",
                            marginBottom: "0px",
                        }}
                    >
                        {articolo.DESCRIZIONE}
                    </p>
                    <p
                    style={{
                        fontSize:"14px",
                        color: "#68ABAD",
                        display: "inline-block",
                        marginBottom: "0px",
                    }}
                >
                    Codice: <span style={{fontFamily: 'Medium'}}>{articolo.CODICE}</span>
                </p>
                </div>
                <div
                    style={{
                        backgroundColor: "#F0F0F0",
                        height: "2px",
                        marginTop: "28px"
                    }}
                />
            </div>

            { articolo.INGREDIENTI && articolo.INGREDIENTI ? (
                <div>
                    <div
                        style={{
                            marginTop: "24px",
                            padding: "0px 20px",
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize:"20px",
                                color: "#68ABAD",
                                display: "inline-block",
                                width: "100%",
                                marginBottom: "0px",
                            }}
                        >
                            Ingredienti
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize:"16px",
                                color: "#808080",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "12px",
                                marginBottom: "0px",
                            }}
                        >
                            {articolo.INGREDIENTI}
                        </p>
                    </div>
                </div>
            ) :(
                <div/>
            )}

            { articolo.TRACCE && articolo.TRACCE ? (
                <div>
                    <div
                        style={{
                            marginTop: "24px",
                            padding: "0px 20px",
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize:"20px",
                                color: "#68ABAD",
                                display: "inline-block",
                                width: "100%",
                                marginBottom: "0px",
                            }}
                        >
                            Allergeni e tracce
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize:"16px",
                                color: "#808080",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "12px",
                                marginBottom: "0px",
                            }}
                        >
                            {articolo.TRACCE}
                        </p>
                    </div>
                </div>
            ) : (
                <div/>
            )}

            { articolo.KCAL && articolo.KCAL ? (
                <div>
                    <div
                        style={{
                            marginTop: "24px",
                            padding: "0px 20px",
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize:"20px",
                                color: "#68ABAD",
                                display: "inline-block",
                                width: "100%",
                                marginBottom: "0px",
                            }}
                        >
                            Tabella nutrizionale&nbsp;
                            <span
                                style={{
                                    fontFamily: "Regular"
                                }}
                            >
                                &#10088;per porzione&#10089;
                            </span>
                        </p>
                        <div
                            style={{
                                backgroundColor: "#F0F0F0",
                                height: "2px",
                                marginTop: "20px"
                            }}
                        />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "16px",

                            }}
                        >
                            <p
                                style={{
                                    fontFamily: "Medium",
                                    fontSize:"14px",
                                    color: "#0F4651",
                                    display: "inline-block",

                                    marginBottom: "0px",
                                }}
                            >
                                ENERGIA
                            </p>
                            <p
                                style={{
                                    fontFamily: "Regular",
                                    fontSize:"16px",
                                    color: "#808080",
                                    display: "inline-block",

                                    marginBottom: "0px",
                                }}
                            >
                                {getValoreEnergetico()}
                            </p>
                        </div>
                        <div
                            style={{
                                backgroundColor: "#F0F0F0",
                                height: "2px",
                                marginTop: "16px"
                            }}
                        />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "16px",

                            }}
                        >
                            <p
                                style={{
                                    fontFamily: "Medium",
                                    fontSize:"14px",
                                    color: "#0F4651",
                                    display: "inline-block",

                                    marginBottom: "0px",
                                }}
                            >
                                GRASSI
                                <br/>
                                di cui saturi
                            </p>
                            <div
                                style={{
                                    textAlign: "right"
                                }}
                            >

                                <p
                                    style={{
                                        fontFamily: "Regular",
                                        fontSize:"16px",
                                        color: "#808080",
                                        display: "inline-block",

                                        marginBottom: "0px",
                                    }}
                                >
                                    {articolo.GRASSI && articolo.GRASSI !== "" ? articolo.GRASSI + " g" : "-"}
                                    <br/>
                                    {articolo["GRASSI SAT"] && articolo["GRASSI SAT"] !== "" ? articolo["GRASSI SAT"] + " g" : "-"}
                                </p>
                            </div>
                        </div>
                        <div
                            style={{
                                backgroundColor: "#F0F0F0",
                                height: "2px",
                                marginTop: "16px"
                            }}
                        />

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "16px",

                            }}
                        >
                            <p
                                style={{
                                    fontFamily: "Medium",
                                    fontSize:"14px",
                                    color: "#0F4651",
                                    display: "inline-block",

                                    marginBottom: "0px",
                                }}
                            >
                                CARBOIDRATI
                                <br/>

                                di cui zuccheri
                                <br/>
                                di cui polioli
                            </p>
                            <div
                                style={{
                                    textAlign: "right"
                                }}
                            >

                                <p
                                    style={{
                                        fontFamily: "Regular",
                                        fontSize:"16px",
                                        color: "#808080",
                                        display: "inline-block",

                                        marginBottom: "0px",
                                    }}
                                >
                                    {articolo.CARBOIDRATI && articolo.CARBOIDRATI !== "" ? articolo.CARBOIDRATI + " g" : "-"}
                                    <br/>
                                    {articolo.ZUCCHERI && articolo.ZUCCHERI !== "" ? articolo.ZUCCHERI + " g" : "-"}
                                    <br/>
                                    {articolo.POLIOLI && articolo.POLIOLI !== "" ? articolo.POLIOLI + " g" : "-"}
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                backgroundColor: "#F0F0F0",
                                height: "2px",
                                marginTop: "16px"
                            }}
                        />

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "16px",

                            }}
                        >
                            <p
                                style={{
                                    fontFamily: "Medium",
                                    fontSize:"14px",
                                    color: "#0F4651",
                                    display: "inline-block",

                                    marginBottom: "0px",
                                }}
                            >
                                FIBRE
                            </p>
                            <p
                                style={{
                                    fontFamily: "Regular",
                                    fontSize:"16px",
                                    color: "#808080",
                                    display: "inline-block",

                                    marginBottom: "0px",
                                }}
                            >
                                {articolo.FIBRE && articolo.FIBRE !== "" ? articolo.FIBRE + " g" : "-"}
                            </p>
                        </div>

                        <div
                            style={{
                                backgroundColor: "#F0F0F0",
                                height: "2px",
                                marginTop: "16px"
                            }}
                        />

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "16px",

                            }}
                        >
                            <p
                                style={{
                                    fontFamily: "Medium",
                                    fontSize:"14px",
                                    color: "#0F4651",
                                    display: "inline-block",

                                    marginBottom: "0px",
                                }}
                            >
                                PROTEINE
                            </p>
                            <p
                                style={{
                                    fontFamily: "Regular",
                                    fontSize:"16px",
                                    color: "#808080",
                                    display: "inline-block",

                                    marginBottom: "0px",
                                }}
                            >
                                {articolo.PROTEINE && articolo.PROTEINE !== "" ? articolo.PROTEINE + " g" : "-"}
                            </p>
                        </div>

                        <div
                            style={{
                                backgroundColor: "#F0F0F0",
                                height: "2px",
                                marginTop: "16px"
                            }}
                        />

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "16px",

                            }}
                        >
                            <p
                                style={{
                                    fontFamily: "Medium",
                                    fontSize:"14px",
                                    color: "#0F4651",
                                    display: "inline-block",

                                    marginBottom: "0px",
                                }}
                            >
                                SALE
                            </p>
                            <p
                                style={{
                                    fontFamily: "Regular",
                                    fontSize:"16px",
                                    color: "#808080",
                                    display: "inline-block",

                                    marginBottom: "0px",
                                }}
                            >
                                {articolo.SALE && articolo.SALE !== "" ? articolo.SALE + " g" : "-"}
                            </p>
                        </div>
                        <div
                            style={{
                                backgroundColor: "#F0F0F0",
                                height: "2px",
                                marginTop: "16px"
                            }}
                        />

                    </div>
                </div>
            ) : (
                <div/>
            )}




            { articolo["TABELLA NUTRIZIONALE INTEGRATORI"] && articolo["TABELLA NUTRIZIONALE INTEGRATORI"] ? (
                <div>
                    <div
                        style={{
                            marginTop: "24px",
                            padding: "0px 20px",
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize:"20px",
                                color: "#68ABAD",
                                display: "inline-block",
                                width: "100%",
                                marginBottom: "0px",
                            }}
                        >
                            Tabella nutrizionale
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize:"16px",
                                color: "#808080",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "12px",
                                marginBottom: "0px",
                            }}
                        >
                            {articolo["TABELLA NUTRIZIONALE INTEGRATORI"]}
                        </p>
                    </div>
                </div>
            ) : (
                <div/>
            )}


            { articolo["CONTENUTO COFANETTO"] && articolo["CONTENUTO COFANETTO"] ? (
                <div>
                    <div
                        style={{
                            marginTop: "24px",
                            padding: "0px 20px",
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize:"20px",
                                color: "#68ABAD",
                                display: "inline-block",
                                width: "100%",
                                marginBottom: "0px",
                            }}
                        >
                            Contenuto cofanetto
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize:"16px",
                                color: "#808080",
                                display: "inline-block",
                                width: "100%",
                                marginTop: "12px",
                                marginBottom: "0px",
                            }}
                        >
                            {articolo["CONTENUTO COFANETTO"]}
                        </p>
                    </div>
                </div>
            ) : (
                <div/>
            )}

            <div
                style={{
                    marginBottom:"200px",
                    padding: "0px 20px",
                }}
            />


            <div
                className="footer"
                style={{
                    position: "fixed",
                    bottom: 0,
                    left:0,
                    height: "180px",
                    borderRadius: "24px 24px 0px 0px",
                    borderTop: "2px solid #F0F0F0",
                    boxShadow: "0px -5px 25px 0px rgba(0, 0, 0, 0.10)",
                    width: "100%",
                    backgroundColor:"#FFFFFF"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "20px",
                        padding: "0px 20px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column"
                            }}
                        >
                            <p
                                style={{
                                    fontFamily: "Regular",
                                    fontSize:"16px",
                                    color: "#808080",
                                    display: "inline-block",
                                    width: "100%",
                                    marginTop: "8px",
                                    marginBottom: "0px",
                                }}
                            >
                                {getPorzioni()}
                            </p>
                            <p
                                style={{
                                    fontFamily: "Medium",
                                    fontSize:"20px",
                                    color: "#68ABAD",
                                    display: "inline-block",
                                    width: "100%",
                                    marginBottom: "0px",
                                }}
                            >
                                {formatPrice(articolo["PREZZOCONF"])}
                            </p>

                        </div>
                        <div
                            style={{
                                display:"flex",
                                flexDirection: "row",
                                alignItems: "center",
                                //justifyContent: "space-between",

                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "#68ABAD",
                                    width: "36px",
                                    height: "36px",
                                    borderRadius: "18px",
                                    marginRight: "20px"
                                }}
                            >
                                <button
                                    onClick={removeQty}
                                    style={{
                                        backgroundColor: "transparent",
                                        border: "none",

                                    }}
                                >
                                    <Remove
                                        style={{
                                            width: "36px",
                                            height: "36px",
                                            color: "white"
                                        }}
                                    />
                                </button>
                            </div>
                            <div
                                style={{
                                    border: "2px solid #D9D9D9",
                                    borderRadius: "12px",
                                    height: "48px",
                                    width: "48px",
                                    display:"flex",
                                    justifyContent:"center",
                                    alignItems:"center"
                                }}
                            >
                                <label
                                    style={{
                                        fontFamily: "Semibold",
                                        fontSize:"18px",
                                        color: "#0F4651",
                                        textAlign: "center",
                                        width: "44px"
                                    }}
                                >
                                    {qty}
                                </label>

                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "#68ABAD",
                                    width: "36px",
                                    height: "36px",
                                    borderRadius: "18px",
                                    marginLeft: "20px",
                                }}
                            >
                                <button
                                    onClick={addQty}
                                    style={{
                                        backgroundColor: "transparent",
                                        border: "none",

                                    }}
                                >
                                    <Add
                                        style={{
                                            width: "36px",
                                            height: "36px",
                                            color: "white"
                                        }}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>

                    <button
                        className="btn-order"
                        style={{
                            marginTop: "20px",
                            width: "100%"
                        }}
                        onClick={addToCart}
                    >
                        Aggiungi all'ordine
                    </button>

                </div>

            </div>
            </>}
        </div>
    )
}
