import React from "react";
import { useState } from "react";
import Add from "@material-ui/icons/Add";
import { Alert } from "@mui/material";
import { formatPrice } from "../utils/prices";

function OrderCell(props){

    const [alertFlag, setAlertFlag] = useState(false);
    
    const articolo = props.articolo;

    function addToCart(){
        setAlertFlag(true);
        props.setCarrello(articolo, 1);
        setTimeout(() => {    
            setAlertFlag(false)
        }, 2000);
    }

    function onDetail(){
        props.onDetail(articolo);
    }

    function getPorzioni(){
        let porzioni = "";
        if(articolo["NPORZ"] && articolo["NPORZ"] !== ""){
            porzioni = articolo["NPORZ"] + " porz. / ";
            if(articolo["GRPORZ"] && articolo["GRPORZ"] !== ""){
                porzioni = porzioni + articolo["GRPORZ"] + " g";
            } else if(articolo["MGPORZ"] && articolo["MGPORZ"] !== ""){
                porzioni = porzioni + articolo["MGPORZ"] + " mg"
            }
        } 
        return porzioni;
    }

    return (
        <div>
            <div className="row"
                style={{
                    padding: "8px 24px",
                }}
            >
                <div 
                    className="col-4"
                    style={{
                        marginTop:"12px",
                        textAlign:"center"
                    }}
                    onClick={onDetail}
                >
                    <img
                        src={require(`../images/best-sellers/${articolo.CODICE}.png`)}
                        alt=''
                        style={{
                            width:"110px",
                            display: "inline-block"
                        }}
                    />
                    
                </div>
                
                <div 
                    className="col-8"
                    style={{
                        marginTop:"8px",
                        textAlign:"left"
                    }}
                    onClick={onDetail}
                >
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize:"17px",
                            color: "#0F4651",
                            display: "inline-block",
                            width: "100%",
                            marginBottom: "0px",
                        }}
                    >
                        {articolo.NOME}
                    </p>
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize:"14px",
                            color: "#808080",
                            display: "inline-block",
                            width: "100%",
                            marginBottom: "0px",
                        }}
                    >
                        {articolo.DESCRIZIONE}
                    </p>
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize:"14px",
                            color: "#808080",
                            display: "inline-block",
                            width: "100%",
                            marginTop: "8px",
                            marginBottom: "0px",
                        }}
                    >
                        {getPorzioni()}
                    </p>
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize:"16px",
                            color: "#68ABAD",
                            display: "inline-block",
                            width: "100%",
                            marginBottom: "0px",
                        }}
                    >
                        {formatPrice(articolo["PREZZOCONF"])} 
                    </p>
                </div>
                <div 
                    className="col-12"
                    style={{
                        marginTop:"8px",
                        textAlign:"left",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end"
                    }}
                >
                    <div 
                        
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#68ABAD",
                            width: "24px",
                            height: "24px",
                            borderRadius: "16px"
                        }}
                    >
                        
                        <button 
                            onClick={addToCart}
                            style={{
                                backgroundColor: "transparent",
                                border: "none",
                                
                            }}
                        >
                            <Add 
                                style={{
                                    width: "24px",
                                    height: "24px",
                                    color: "white" 
                                }}

                            />
                        </button>
                        
                    </div>
                    
                </div>
 
            </div>
            {alertFlag && <Alert severity="success">Prodotto aggiunto al carrello!</Alert>}
            <div 
                style={{
                    backgroundColor: "#F0F0F0",
                    height: "2px",
                    marginTop: "16px"
                }}
            />
        </div>
    )
}

export default OrderCell;