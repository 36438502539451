// Modal.js
 
import React from "react";
 
const InfoModal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
 
    return (
        <div
            
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 100
            }}
        >
            <div
                style={{
                    background: "white",
                    
                    width: 380,
                    margin: "auto",
                    padding: "24px",
                    border: "2px solid #F0F0F0",
                    borderRadius: "24px",
                    boxShadow: "0px -5px 25px 0px rgba(0, 0, 0, 0.10)",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {children}
            </div>
        </div>
    );
};
 
export default InfoModal;