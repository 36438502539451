import './App.css';
import './font/Graphik-Light.otf';
import './font/Graphik-Regular.otf';
import './font/Graphik-Semibold.otf';
import './font/Graphik-Bold.otf';
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { HomeView } from './view/HomeView';
import { ConfirmOrderView } from './view/ConfirmOrderView';
import { SuccessOrderView } from './view/SuccessOrderView';
import { SendRequestView } from './view/SendRequestView';
import { SuccessRequestView } from './view/SuccessRequestView';
import { ErrorView } from './view/ErrorView';
import '@dotlottie/player-component';
import { useParams, useSearchParams } from 'react-router-dom';

import { SERVICE_URL } from '.';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { ProductDetailView } from './view/ProductDetailView';
import { RecapOrderView } from './view/RecapOrderView';


function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [requestError, setRequestError] = useState(null);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState();
  const isMobile = () => window.matchMedia && window.matchMedia("(max-width: 480px)").matches

  useEffect(() => {
    executeGetDataInfo();
  }, [])

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }


  const executeGetDataInfo = () => {
    setIsLoading(true);

    const actualToken = getCookie("kalibratoken")//window.location.pathname.replace(/\//gi, '');
    
    if(!actualToken) {
      setIsLoading(false);
      setRequestError({error:true, message: "Missing token"});
      return;
    }

    setToken(actualToken);

    axios.get( SERVICE_URL + '/start', {
      headers: {
        'Authorization' : 'Bearer ' + actualToken
      }
    }).then((response) => {
        if (response.data) {
          //setUnitaMisure(response.data.unita_misura);
          setCategories(response.data.categories);
          setUser(response.data.user);
          setIsLoading(false);

        }

    }).catch((error) => {
      console.error(error);
      setIsLoading(false);
      setRequestError(error)
    });
}


  return (
    <div style={{"zoom": (isMobile()? "1.1": "1.0")}}>
      {isLoading ? (
        <div
          style={{
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            height:"100vh"
          }}
        >
          <dotlottie-player src="https://lottie.host/90a4cd86-6db1-4a26-a699-a36fd63d1fce/tEBIPFUn7o.json" mode="normal" background="transparent" speed="1" style={{width: "300px", height: "300px", display: "block"}} direction="1" loop autoplay></dotlottie-player>
        </div>
      ) : !requestError ?  (
          <div>
            <Router>
              <Routes>
                <Route exact path="/" element={<HomeView token={token} categories={categories} user={user} errore={requestError}/>}/>
                <Route exact path="/productDetail" element={<ProductDetailView token={token} user={user} />}  />
                <Route exact path="/carrello" element={<ConfirmOrderView token={token} user={user} />} />
                <Route exact path="/requestSuccess" element={<SuccessRequestView token={token} user={user} />} />
                <Route exact path="/order/:id" element={<RecapOrderView />} />
                <Route exact path="/errore" element={<ErrorView errore={requestError} token={token} user={user} />} />
              </Routes>
            </Router>
          </div>
        ) : (
          <Router>
            <Routes>
              <Route exact path="/" element={<ErrorView errore={requestError} token={token} user={user} />} />
              <Route exact path="/errore" element={<ErrorView errore={requestError} token={token} user={user} />} />
              <Route exact path="/order/:id" element={<RecapOrderView />} />
            </Routes>
          </Router>

        )}
    </div>
  );
}

export default App;
