import React, { useRef } from "react";
import { useState, useEffect, useMemo } from "react";
import { LogoTop } from "..";
import  ArrowBack from "@material-ui/icons/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { CarrelloCell } from "../components/CarrelloCell";
import CarrelloModal from "../components/CarrelloModal";
import axios from "axios";
import { SERVICE_URL } from "..";
import Info from "@material-ui/icons/Info";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import InfoModal from "../components/InfoModal";
import CodFiscale from 'codice-fiscale-js'


import "react-datepicker/dist/react-datepicker.css";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import it from 'date-fns/locale/it';
import {formatPricePerThousand, parsePrice} from "../utils/prices";
import { formatPrice } from "../utils/prices";

registerLocale('it', it);
setDefaultLocale("it");

const initializeAddress = (user) =>
    user?.["Indirizzo Completo"] ||
        (user["CAP"]
            ? `${user["Indirizzo"]}, ${user["CAP"]}, ${user["Località"]}, ${user["Provincia"]}`
            : '')

export function ConfirmOrderView(props) {
    const { state } = useLocation();
    const navigate = useNavigate();

    const [carrello, setCarrello] = useState(state?.carrello || []);
    const [articoloSelezionato, setArticoloSelezionato] = useState();
    const [open, setOpen] = useState(false);
    const [openPrivacy, setOpenPrivacy] = useState(false);
    const [openCarta, setOpenCarta] = useState(false);
    const [openContrassegno, setOpenContrassegno] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [privacyCheck, setPrivacyCheck] = useState(false);
    const [spedizioneCheck, setSpedizioneCheck] = useState(true);
    const [codMedico, setCodMedico] = useState("");
    const [codPrescrizione, setCodPrescrizione] = useState("");

    const [nome, setNome] = useState(props.user["Fatturazione"]?.["Nome Cliente"] || props.user["Nome Cliente"] || '');
    const [cognome, setCognome] = useState(props.user["Fatturazione"]?.["Cognome Cliente"] || '');
    const [indirizzo, setIndirizzo] = useState(initializeAddress(props.user["Fatturazione"]));
    const [codFiscale, setCodFiscale] = useState(props.user["CodFiscale"]);

    const [nomeSped, setNomeSped] = useState(props.user["Nome Cliente"] || '');
    const [cognomeSped, setCognomeSped] = useState(props.user["Cognome Cliente"] || '');
    const [indirizzoSped, setIndirizzoSped] = useState(initializeAddress(props.user));

    const [validationErrors, setValidationErrors] = useState({ doctor: undefined, code: undefined, codFiscale: undefined })
    const flagCostoSpedizione = useMemo(() => carrello
            .filter(product => product["CATEGORIA"] === "INTEGRATORI MICRONUTRIZIONALI")
            .reduce((total, product) => total + parsePrice(product["PREZZOCONF"] * parseInt(product.qty)), 0) < 85 &&
        carrello.every((product) => product["CATEGORIA"] !== "COFANETTI INTEGRATORI")
    , [carrello])
            

    const costoSpedizione = 8.80;

    const labelEl = useRef(undefined)

    const [isCarta, setIsCarta] = useState(true);
    const [isContrassegno, setIsContrassegno] = useState(false);

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    useEffect(() => {
        if (carrello.length) {
            window.scrollTo(0, 0);
        } else {
            navigate('/', { replace: true })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (labelEl?.current) {
            labelEl.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [labelEl])

    const handleClose = () => {
        setOpen(false);
    }

    const handleOpen = (articolo) => {
        setArticoloSelezionato(articolo);
        setOpen(true);
    }

    const handleClosePrivacy = () => {
        setOpenPrivacy(false);
    }

    const handleCloseCarta = () => {
        setOpenCarta(false);
    }

    const handleCloseContrassegno = () => {
        setOpenContrassegno(false);
    }

    const openModalPrivacy = () => {
        setOpenPrivacy(true);
    }

    const openModalCarta = () => {
        setOpenCarta(true);
    }

    const openModalContrassegno = () => {
        setOpenContrassegno(true);
    }

    function handlePagamento(e){
        if(e.target.value === "carta"){
            setIsCarta(true);
            setIsContrassegno(false);
        } else {
            setIsCarta(false);
            setIsContrassegno(true);
        }
    }

    function goBack(){

        navigate("/", {
            replace: true,
            state: {
                carrello: carrello
            }
        });
    }

    function eliminaArticolo(){
        let tmpArticles = [...carrello];
        var indexToDelete = -1;
        tmpArticles.forEach((elem,index) => {
            if(elem.CODICE === articoloSelezionato.CODICE ){
                indexToDelete = index;
            }
        });
        tmpArticles.splice(indexToDelete, 1);
        setCarrello(tmpArticles);
        setOpen(false);
        if(tmpArticles.length === 0){
            navigate("/", {
                replace: true,
                state: {
                    carrello: []
                }
            });
        }
    }

    function calculateNumber(){
        let currentArticles = [...carrello];
        let tot = 0;
        currentArticles.forEach((elem) => {
            const price = parsePrice(elem["PREZZOCONF"])
            tot = tot + (price * parseInt(elem.qty));
        })
        if(flagCostoSpedizione){
            tot = tot + costoSpedizione
        }
        return formatPricePerThousand(tot)
    }

    function calculateTot(){
        let currentArticles = [...carrello];
        let tot = 0;
        currentArticles.forEach((elem) => {
            const price = parsePrice(elem["PREZZOCONF"])
            tot = tot + (price * parseInt(elem.qty));
        })
        if(flagCostoSpedizione){
            tot = tot + costoSpedizione
        }
        return formatPrice(tot)
    }

    function calculateTotProdotti(){
        let currentArticles = [...carrello];
        let tot = 0;
        currentArticles.forEach((elem) => {
            const price = parsePrice(elem["PREZZOCONF"])
            tot = tot + (price * parseInt(elem.qty));
        })
        return formatPrice(tot)
    }



    function ordina(){
        if(checkData()){
            let body = {
                "articles": carrello,
                "code": codPrescrizione,
                "doctor": codMedico,
                "amount": calculateNumber(),
                "payment": isCarta ? "CARTA" : "CONTRASSEGNO",
                "Telefono": props.user.Telefono,
                "billing": {
                    "Nome Cliente": nome,
                    "Cognome Cliente": cognome,
                    "Indirizzo": indirizzo,
                    "CodFiscale": codFiscale
                },
                "shipping": spedizioneCheck ? {
                    "Nome Cliente": nome,
                    "Cognome Cliente": cognome,
                    "Indirizzo": indirizzo,
                } : {
                    "Nome Cliente": nomeSped,
                    "Cognome Cliente": cognomeSped,
                    "Indirizzo": indirizzoSped,
                }
            }

            setIsLoading(true);

            axios.post(SERVICE_URL + '/order', body, {
                headers: {
                    "Access-Control-Allow-Origin" : "*",
                    "Access-Control-Allow-Methods": "POST, GET, PUT",
                    "Access-Control-Allow-Headers" : "Content-type",
                    "Content-type": "Application/json",
                    'Authorization' : `Bearer ${props.token}`
                }
            }).then((response) => {
                if(response.data){

                    setIsLoading(false);
                    navigate("/requestSuccess");
                }
                setValidationErrors({ doctor: undefined, code: undefined, codFiscale: validateCodFiscale() })
            }).catch((error) => {
                console.error(error.response);
                setValidationErrors({ ...error.response.data, codFiscale: validateCodFiscale() })
                setIsLoading(false);

            })
        }


    }

    const validateCodFiscale = () => {
        if (!CodFiscale.check(codFiscale)) {
            return 'Il codice fiscale inserito non è valido'
        }

        return undefined
    }

    function checkData(){
        return privacyCheck && codMedico !== "" && codPrescrizione !== "" && nome !== "" && cognome !== "" && indirizzo !== "" && codFiscale !== "" && (spedizioneCheck || (nomeSped !== "" && cognomeSped !== "" && indirizzoSped !== ""));
    }

    return (
        <div
        className="container"
            style={{
                marginTop: "0px",
                position: "relative",
                padding:"0px"
            }}
        >
            <div
                style={{
                    display:`${isLoading ? "flex" : "none"}`,
                    justifyContent:"center",
                    alignItems:"center",
                    height:"100%",
                    width: "100%",
                    position: "absolute",
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    zIndex: "100"
                }}
                >
                <dotlottie-player src="https://lottie.host/90a4cd86-6db1-4a26-a699-a36fd63d1fce/tEBIPFUn7o.json" mode="normal" background="transparent" speed="1" style={{width: "300px", height: "300px", display: "block"}} direction="1" loop autoplay></dotlottie-player>
            </div>

            <div
                className="row"
                style={{
                    padding: "0px 20px",
                    backgroundColor: "#0F4651",
                    height: "120px",
                    display: "flex",
                    alignItems: "center"
                }}
            >
                <div
                    onClick={goBack}
                    className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2"
                    style={{
                        marginTop: "16px"
                    }}
                >
                    <ArrowBack
                        style={{
                            width: "32px",
                            height: "32px",
                            color: "white"
                        }}
                    />
                </div>
                <div
                    className="col-lg-8 col-md-8 col-sm-8 col-xs-8 col-8"
                    style={{
                        marginTop: "16px",
                        textAlign: "center"
                    }}
                >
                    <img
                        src={LogoTop}
                        alt=''
                        style={{
                            height: "32px",
                            display: "inline-block"
                        }}
                    />
                </div>
                <div
                    className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2"
                    style={{
                        marginTop: "16px",
                        display: "flex",
                        justifyContent: "flex-end"
                    }}
                >
                    <div
                        onClick={openModalPrivacy}
                        style={{
                            width: "40px",
                            height: "40px",
                            cursor:"pointer",
                            borderRadius: "20px",
                            justifyContent:"center",
                            alignItems:"center",
                            display:"flex"
                        }}
                    >
                        <Info
                            style={{
                                width: "32px",
                                height: "32px",
                                color: "#68ABAD",

                            }}
                        />
                    </div>

                </div>

            </div>




            <div
                className="row"
                style={{
                    marginTop: "24px",
                    textAlign:"center",
                    padding: "0px 24px",
                }}
            >
                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize:"22px",
                        color: "#004750",
                        display: "inline-block",
                        width: "100%",
                        textAlign: "center !important",
                        marginBottom: "0px"
                    }}
                >
                    Conferma il tuo ordine
                </p>

            </div>
            <div
                className="row"
                style={{
                    marginTop: "12px",
                    textAlign: "center",
                    padding: "0px 24px",
                }}
            >
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize:"16px",
                        color: "#808080",
                        display: "inline-block",
                        width: "100%",
                        textAlign: "center !important",
                        marginBottom: "0px"
                    }}
                >
                    Conferma gli alimenti selezionati e clicca per concludere l'ordine
                </p>

            </div>

            <div
                style={{
                    backgroundColor: "#F0F0F0",
                    height: "2px",
                    marginTop: "24px",
                    padding: "0px 24px",
                }}
            />

            {/* <CarrelloCell key="123" articolo={{}} showModal={handleOpen}/>
            <CarrelloCell key="345" articolo={{}} showModal={handleOpen}/> */}
            {carrello.map((articolo) => (
                <CarrelloCell key={articolo.CODICE} articolo={articolo} showModal={handleOpen}/>
            ))}
            {flagCostoSpedizione && (
                <>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "baseline",
                            padding: "0px 24px",
                            width: "100%",
                            marginTop: "16px"
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "Regular",
                                color: "#808080",
                                fontSize:"14px",
                                display: "inline-block",
                                marginRight: "12px",
                                textAlign: "center !important",
                                marginBottom: "0px"
                            }}
                        >
                            Totale prodotti:
                        </p>
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize:"14px",
                                color: "#68ABAD",
                                display: "inline-block",

                                textAlign: "center !important",
                                marginBottom: "0px"
                            }}
                        >
                            {calculateTotProdotti()}
                        </p>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "baseline",
                            padding: "0px 24px",
                            width: "100%",
                            marginTop: "4px"
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "Regular",
                                color: "#808080",
                                fontSize:"14px",
                                display: "inline-block",
                                marginRight: "12px",
                                textAlign: "center !important",
                                marginBottom: "0px"
                            }}
                        >
                            Costo spedizione:
                        </p>
                        <p
                            style={{
                                fontFamily: "Medium",
                                fontSize:"14px",
                                color: "#68ABAD",
                                display: "inline-block",

                                textAlign: "center !important",
                                marginBottom: "0px"
                            }}
                        >
                            {formatPrice(costoSpedizione)}
                        </p>
                    </div>
                </>
            )}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "baseline",
                    padding: "0px 24px",
                    width: "100%",
                    marginTop: "16px"
                }}
            >
                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize:"16px",
                        color: "#808080",
                        display: "inline-block",
                        marginRight: "12px",
                        textAlign: "center !important",
                        marginBottom: "0px"
                    }}
                >
                    Totale ordine:
                </p>
                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize:"20px",
                        color: "#0F4651",
                        display: "inline-block",

                        textAlign: "center !important",
                        marginBottom: "0px"
                    }}
                >
                    {calculateTot()}
                </p>
            </div>

            <div
                style={{
                    marginTop: "48px",
                    padding: "0px 20px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize:"20px",
                            color: "#68ABAD",
                            display: "inline-block",
                            width: "100%",
                            marginBottom: "0px",
                        }}
                    >
                        Codici Richiesti
                    </p>
                    <div
                        onClick={openModalPrivacy}
                        style={{
                            width: "20px",
                            height: "20px",
                            cursor:"pointer",
                            borderRadius: "10px",
                            justifyContent:"center",
                            alignItems:"center",
                            display:"flex"
                        }}
                    >
                        <InfoOutlined
                            style={{
                                width: "28px",
                                height: "28px",
                                color: "#808080",

                            }}
                        />

                    </div>
                </div>
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize:"16px",
                        color: "#808080",
                        display: "inline-block",
                        width: "100%",
                        marginTop: "0px",
                        marginBottom: "0px",
                    }}
                >
                    Inserisci i codici di riferimento
                </p>
                <p
                    ref={validationErrors?.code ? labelEl : undefined}
                    style={{
                        fontFamily: "Medium",
                        fontSize:"12px",
                        color: "#808080",
                        display: "inline-block",
                        width: "100%",
                        marginTop: "24px",
                        marginBottom: "4px",
                    }}
                >
                    CODICE MODULO D'ORDINE*&nbsp;
                    <span
                        style={{
                            fontFamily: "Regular"
                        }}
                    >
                        &#10088;composto da 7 caratteri, che ti è stato rilasciato dal tuo Esperto in Nutrizione dopo la visita&#10089;
                    </span>
                </p>
                <input type="text"
                    value={codPrescrizione}
                    onChange={(e) => {
                        setValidationErrors({ ...validationErrors, code: undefined })
                        setCodPrescrizione(e.target.value)
                    }}
                    style={{
                        height:"52px",
                        width: "100%",
                        backgroundColor: "#FFFFFF",
                        border: '1px solid ' + (validationErrors?.code ? 'red' : '#D9D9D9'),
                        borderRadius: "8px",
                        padding: "0px 12px",
                        fontFamily: "Regular",
                        fontSize: "16px",
                        color: validationErrors?.code ? 'red' : "#0F4651"
                    }}
                />
                { validationErrors?.code &&
                    <p style={{
                        marginTop: '4px',
                        color: 'red',
                        fontSize:'12px',
                        fontFamily: 'Regular'
                    }}>{ validationErrors.code }</p> }
                <p
                    ref={validationErrors?.doctor ? labelEl : undefined}
                    style={{
                        fontFamily: "Medium",
                        fontSize:"12px",
                        color: "#808080",
                        display: "inline-block",
                        width: "100%",
                        marginTop: "16px",
                        marginBottom: "4px",
                    }}
                >
                    CODICE ESPERTO IN NUTRIZIONE*&nbsp;
                    <span
                        style={{
                            fontFamily: "Regular"
                        }}
                    >
                        &#10088;codice identificativo del tuo Esperto in Nutrizione&#10089;
                    </span>
                </p>
                <input type="text"
                    value={codMedico}
                    onChange={(e) => {
                        setValidationErrors({ ...validationErrors, doctor: undefined })
                        setCodMedico(e.target.value)
                    }}
                    style={{
                        height:"52px",
                        width: "100%",
                        backgroundColor: "#FFFFFF",
                        border: '1px solid ' + (validationErrors?.doctor ? 'red' : '#D9D9D9'),
                        borderRadius: "8px",
                        padding: "0px 12px",
                        fontFamily: "Regular",
                        fontSize: "16px",
                        color: validationErrors?.doctor ? 'red' : "#0F4651"
                    }}
                />
                { validationErrors?.doctor &&
                    <p style={{
                        marginTop: '4px',
                        color: 'red',
                        fontSize:'12px',
                        fontFamily: 'Regular'
                    }}>{ validationErrors.doctor }</p> }

            </div>


            <div
                style={{
                    marginTop: "48px",
                    padding: "0px 20px",
                }}
            >
                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize:"20px",
                        color: "#68ABAD",
                        display: "inline-block",
                        width: "100%",
                        marginBottom: "0px",
                    }}
                >
                    Modalità pagamento
                </p>
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize:"16px",
                        color: "#808080",
                        display: "inline-block",
                        width: "100%",
                        marginTop: "0px",
                        marginBottom: "0px",
                    }}
                >
                    Seleziona la modalità di pagamento desiderata:
                </p>

                <div
                    style={{
                        display:"flex",
                        flexDirection: "column"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >

                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "24px"
                            }}
                        >
                            <input type="radio" id="carta" name="pagamento" value="carta" checked={isCarta} onChange={handlePagamento}
                                style={{
                                    width: "24px",
                                    height: "24px",
                                    marginRight: "24px",
                                    accentColor: "rgb(15, 70, 81)",
                                }}
                            />
                            <div
                                style={{
                                    display:"flex",
                                    flexDirection: "column"
                                }}
                            >
                                <label htmlFor="carta"
                                    style={{
                                        fontFamily: "Medium",
                                        fontSize:"16px",
                                        color: "#0F4651",
                                        marginBottom: "0px"
                                    }}
                                >
                                    CARTA / BONIFICO
                                </label>
                                <label htmlFor="carta"
                                    style={{
                                        fontFamily: "Regular",
                                        fontSize:"14px",
                                        color: "#979797",
                                        marginTop: "0px"
                                    }}
                                >
                                    Riceverai il link per il pagamento
                                </label>
                            </div>
                        </div>
                        <div
                            onClick={openModalCarta}
                            style={{
                                width: "20px",
                                height: "20px",
                                cursor:"pointer",
                                borderRadius: "10px",
                                justifyContent:"center",
                                alignItems:"center",
                                display:"flex"
                            }}
                        >
                            <InfoOutlined
                                style={{
                                    width: "28px",
                                    height: "28px",
                                    color: "#808080",

                                }}
                            />

                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "24px"
                            }}
                        >
                            <input type="radio" id="contrassegno" name="pagamento" value="contrassegno" checked={isContrassegno} onChange={handlePagamento}
                                style={{
                                    width: "24px",
                                    height: "24px",
                                    marginRight: "24px",
                                    accentColor: "rgb(15, 70, 81)",
                                }}
                            />
                        <div
                                style={{
                                    display:"flex",
                                    flexDirection: "column"
                                }}
                            >
                                <label htmlFor="contrassegno"
                                    style={{
                                        fontFamily: "Medium",
                                        fontSize:"16px",
                                        color: "#0F4651",
                                        marginBottom: "0px"
                                    }}
                                >
                                    CONTRASSEGNO
                                </label>
                                <label htmlFor="contrassegno"
                                    style={{
                                        fontFamily: "Regular",
                                        fontSize:"14px",
                                        color: "#979797",
                                        marginTop: "0px"
                                    }}
                                >
                                    Pagherai alla consegna
                                </label>
                            </div>
                        </div>
                        <div
                            onClick={openModalContrassegno}
                            style={{
                                width: "20px",
                                height: "20px",
                                cursor:"pointer",
                                borderRadius: "10px",
                                justifyContent:"center",
                                alignItems:"center",
                                display:"flex"
                            }}
                        >
                            <InfoOutlined
                                style={{
                                    width: "28px",
                                    height: "28px",
                                    color: "#808080",

                                }}
                            />

                        </div>
                    </div>
                </div>

            </div>




            <div
                style={{
                    marginTop: "56px",
                    padding: "0px 20px",
                }}
            >
                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize:"20px",
                        color: "#68ABAD",
                        display: "inline-block",
                        width: "100%",
                        marginBottom: "0px",
                    }}
                >
                    Dati di fatturazione
                </p>
                <p
                    style={{
                        fontFamily: "Regular",
                        fontSize:"16px",
                        color: "#808080",
                        display: "inline-block",
                        width: "100%",
                        marginTop: "0px",
                        marginBottom: "0px",
                    }}
                >
                    Inserisci i dati personali per la fatturazione
                </p>
                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize:"12px",
                        color: "#808080",
                        display: "inline-block",
                        width: "100%",
                        marginTop: "24px",
                        marginBottom: "4px",
                    }}
                >
                    NOME*
                </p>
                <input type="text"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    style={{
                        height:"52px",
                        width: "100%",
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #D9D9D9",
                        borderRadius: "8px",
                        padding: "0px 12px",
                        fontFamily: "Regular",
                        fontSize: "16px",
                        color: "#0F4651"
                    }}
                />

                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize:"12px",
                        color: "#808080",
                        display: "inline-block",
                        width: "100%",
                        marginTop: "24px",
                        marginBottom: "4px",
                    }}
                >
                    COGNOME*
                </p>
                <input type="text"
                    value={cognome}
                    onChange={(e) => setCognome(e.target.value)}
                    style={{
                        height:"52px",
                        width: "100%",
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #D9D9D9",
                        borderRadius: "8px",
                        padding: "0px 12px",
                        fontFamily: "Regular",
                        fontSize: "16px",
                        color: "#0F4651"
                    }}
                />

                <p
                    style={{
                        fontFamily: "Medium",
                        fontSize:"12px",
                        color: "#808080",
                        display: "inline-block",
                        width: "100%",
                        marginTop: "24px",
                        marginBottom: "4px",
                    }}
                >
                    INDIRIZZO*&nbsp;
                    <span
                        style={{
                            fontFamily: "Regular"
                        }}
                    >
                        &#10088;via, civico, CAP, città, provincia&#10089;
                    </span>
                </p>
                <input type="text"
                    value={indirizzo}
                    onChange={(e) => setIndirizzo(e.target.value)}
                    style={{
                        height:"52px",
                        width: "100%",
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #D9D9D9",
                        borderRadius: "8px",
                        padding: "0px 12px",
                        fontFamily: "Regular",
                        fontSize: "16px",
                        color: "#0F4651"
                    }}
                />

                <p
                    ref={validationErrors?.codFiscale ? labelEl : undefined}
                    style={{
                        fontFamily: "Medium",
                        fontSize:"12px",
                        color: "#808080",
                        display: "inline-block",
                        width: "100%",
                        marginTop: "24px",
                        marginBottom: "4px",
                    }}
                >
                    CODICE FISCALE*
                </p>
                <input type="text"
                    value={codFiscale}
                    onChange={(e) => {
                        setValidationErrors({ ...validationErrors, codFiscale: undefined })
                        setCodFiscale(e.target.value.toUpperCase())
                    }}
                    style={{
                        height:"52px",
                        width: "100%",
                        backgroundColor: "#FFFFFF",
                        border: '1px solid ' + (validationErrors?.codFiscale ? 'red' : '#D9D9D9'),
                        borderRadius: "8px",
                        padding: "0px 12px",
                        fontFamily: "Regular",
                        fontSize: "16px",
                        color: validationErrors?.codFiscale ? 'red' : "#0F4651"
                    }}
                />
                { validationErrors?.codFiscale &&
                    <p style={{
                        marginTop: '4px',
                        color: 'red',
                        fontSize:'12px',
                        fontFamily: 'Regular'
                    }}>{ validationErrors.codFiscale }</p> }
            </div>

            <div
                style={{
                    width:"100%",
                    textAlign: "left",
                    marginTop: "32px",
                    padding: "0px 24px",
                    display: "flex",
                    alignItems: "center"
                }}
            >
                <input
                    type="checkbox"
                    id="checkSpedizione"
                    checked={spedizioneCheck}
                    onChange={() => setSpedizioneCheck(!spedizioneCheck)}
                    style={{
                        accentColor: "rgb(15, 70, 81)",
                        color: "white",
                        width: "24px",
                        height: "24px",
                        marginRight: "12px"
                    }}
                />
                <label
                    htmlFor="checkSpedizione"
                    style={{
                        fontFamily: "Regular",
                        fontSize: "16px",
                        color: "#808080"
                    }}
                >
                    Usa gli stessi dati della fatturazione per la spedizione
                </label>
            </div>
            {!spedizioneCheck && (
                <div
                    style={{
                        marginTop: "48px",
                        padding: "0px 20px",
                    }}
                >
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize:"20px",
                            color: "#68ABAD",
                            display: "inline-block",
                            width: "100%",
                            marginBottom: "0px",
                        }}
                    >
                        Dati di spedizione
                    </p>
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize:"16px",
                            color: "#808080",
                            display: "inline-block",
                            width: "100%",
                            marginTop: "0px",
                            marginBottom: "0px",
                        }}
                    >
                        Inserisci i dati personali per la spedizione
                    </p>
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize:"12px",
                            color: "#808080",
                            display: "inline-block",
                            width: "100%",
                            marginTop: "24px",
                            marginBottom: "4px",
                        }}
                    >
                        NOME*
                    </p>
                    <input type="text"
                        value={nomeSped}
                        onChange={(e) => setNomeSped(e.target.value)}
                        style={{
                            height:"52px",
                            width: "100%",
                            backgroundColor: "#FFFFFF",
                            border: "1px solid #D9D9D9",
                            borderRadius: "8px",
                            padding: "0px 12px",
                            fontFamily: "Regular",
                            fontSize: "16px",
                            color: "#0F4651"
                        }}
                    />

                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize:"12px",
                            color: "#808080",
                            display: "inline-block",
                            width: "100%",
                            marginTop: "24px",
                            marginBottom: "4px",
                        }}
                    >
                        COGNOME*
                    </p>
                    <input type="text"
                        value={cognomeSped}
                        onChange={(e) => setCognomeSped(e.target.value)}
                        style={{
                            height:"52px",
                            width: "100%",
                            backgroundColor: "#FFFFFF",
                            border: "1px solid #D9D9D9",
                            borderRadius: "8px",
                            padding: "0px 12px",
                            fontFamily: "Regular",
                            fontSize: "16px",
                            color: "#0F4651"
                        }}
                    />


                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize:"12px",
                            color: "#808080",
                            display: "inline-block",
                            width: "100%",
                            marginTop: "24px",
                            marginBottom: "4px",
                        }}
                    >
                        INDIRIZZO*&nbsp;
                        <span
                            style={{
                                fontFamily: "Regular"
                            }}
                        >
                            &#10088;via, civico, città, provincia&#10089;
                        </span>
                    </p>
                    <input type="text"
                        value={indirizzoSped}
                        onChange={(e) => setIndirizzoSped(e.target.value)}
                        style={{
                            height:"52px",
                            width: "100%",
                            backgroundColor: "#FFFFFF",
                            border: "1px solid #D9D9D9",
                            borderRadius: "8px",
                            padding: "0px 12px",
                            fontFamily: "Regular",
                            fontSize: "16px",
                            color: "#0F4651"
                        }}
                    />
                </div>
            )}

            <div
                style={{
                    width:"100%",
                    textAlign: "left",
                    marginTop: "60px",
                    padding: "0px 24px",
                    display: "flex",
                    alignItems: "center"
                }}
            >
                <p
                    style={{
                        fontFamily: "Regular",
                        color: "#808080",
                        fontSize: "14px"
                    }}
                >
                    * Campi obbligatori
                </p>
            </div>




            <div
                style={{
                    backgroundColor: "white",
                    height: "220px"
                }}
            />


            <CarrelloModal isOpen={open} onClose={handleClose}>
                <>
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize:"18px",
                            color: "#808080",
                            textAlign: "center !important",
                            marginBottom: "0px",
                            marginTop: "12px"
                        }}
                    >
                        Sei sicuro di voler eliminare questo alimento dal tuo ordine?
                    </p>
                    <div
                        style={{
                            marginTop: "32px"
                        }}
                    >
                        <button
                            className="btn-black"

                            onClick={eliminaArticolo}
                        >
                            Elimina alimento
                        </button>
                    </div>
                    <div
                        onClick={handleClose}
                        style={{
                            marginTop:"24px",
                        }}
                    >
                        <p
                            style={{
                                fontFamily: "Medium",
                                textAlign: "center",
                                fontSize:"18px",
                                color: "#808080",
                                textDecoration: "underline"
                            }}
                        >
                            Annulla
                        </p>
                    </div>
                </>
            </CarrelloModal>

            <InfoModal isOpen={openPrivacy} onClose={handleClosePrivacy}>
                <>
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize:"22px",
                            color: "#004750",
                            textAlign: "center !important",
                            marginBottom: "24px"
                        }}
                    >
                        Ti ricordiamo che...
                    </p>
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize:"16px",
                            color: "#808080",
                            textAlign: "center !important",
                            marginBottom: "0px"
                        }}
                    >
                        Per effettuare un ordine di alimenti Kalibra®, è sempre&nbsp;
                        <span
                            style={{
                                fontFamily: "Semibold",
                            }}
                        >
                            necessario essere in possesso di una prescrizione valida rilasciata dal tuo Esperto in Nutrizione.
                        </span>
                        <br/>
                        <br/>
                        Se non hai ancora effettuato la visita nutrizionale,&nbsp;
                        <span
                            style={{
                                fontFamily: "Semibold",
                            }}
                        >
                            contattaci per conoscere gli Esperti più vicini a te.
                        </span>
                    </p>

                    <button
                        className="btn-light"
                        onClick={handleClosePrivacy}
                        style={{
                            marginTop:"24px",
                            zIndex: 1000
                        }}
                    >
                        HO CAPITO
                    </button>

                </>
            </InfoModal>

            <InfoModal isOpen={openCarta} onClose={handleCloseCarta}>
                <>
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize:"22px",
                            color: "#004750",
                            textAlign: "center !important",
                            marginBottom: "24px"
                        }}
                    >
                        Pagamento con carta / bonifico
                    </p>
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize:"16px",
                            color: "#808080",
                            textAlign: "center !important",
                            marginBottom: "0px"
                        }}
                    >
                        Selezionando questa modalità di pagamento riceverai un link attraverso il quale ti sarà possibile pagare in pochi secondi con carta di credito oppure tramite bonifico, in modo rapido, affidabile e sicuro

                    </p>

                    <button
                        className="btn-light"
                        onClick={handleCloseCarta}
                        style={{
                            marginTop:"24px",
                            zIndex: 1000
                        }}
                    >
                        HO CAPITO
                    </button>

                </>
            </InfoModal>

            <InfoModal isOpen={openContrassegno} onClose={handleCloseContrassegno}>
                <>
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize:"22px",
                            color: "#004750",
                            textAlign: "center !important",
                            marginBottom: "24px"
                        }}
                    >
                        Pagamento con contrassegno
                    </p>
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize:"16px",
                            color: "#808080",
                            textAlign: "center !important",
                            marginBottom: "0px"
                        }}
                    >
                        Selezionando questa modalità di pagamento potrai pagare alla consegna del tuo ordine

                    </p>

                    <button
                        className="btn-light"
                        onClick={handleCloseContrassegno}
                        style={{
                            marginTop:"24px",
                            zIndex: 1000
                        }}
                    >
                        HO CAPITO
                    </button>

                </>
            </InfoModal>

            <div
                className="footer"
                style={{
                    display:`${isLoading ? "none" : "block"}`,
                    position: "fixed",
                    bottom: 0,
                    left:0,
                    height: "160px",
                    borderRadius: "24px 24px 0px 0px",
                    borderTop: "2px solid #F0F0F0",
                    boxShadow: "0px -5px 25px 0px rgba(0, 0, 0, 0.10)",
                    width: "100%",
                    backgroundColor:"#FFFFFF"
                }}
            >

                <div
                    style={{
                        width:"100%",
                        textAlign: "left",
                        marginTop: "24px",
                        padding: "0px 24px",
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <input
                        type="checkbox"
                        id="checkPrivacy"
                        checked={privacyCheck}
                        onChange={() => setPrivacyCheck(!privacyCheck)}
                        style={{
                            accentColor: "rgb(15, 70, 81)",
                            color: "white",
                            width: "24px",
                            height: "24px",
                            marginRight: "12px"
                        }}
                    />
                    <label
                        htmlFor="checkPrivacy"
                        style={{
                            fontFamily: "Regular",
                            fontSize: "14px",
                            color: "#808080"
                        }}
                    >

                        Ho letto e accetto l'
                        <a
                            target="_blank"
                            href="https://kalibradiet.com/privacy-policy/"
                            style={{
                                color:"#68ABAD"
                            }}
                            rel="noreferrer"
                        >
                            informativa Privacy
                        </a>
                        &nbsp;e le&nbsp;
                        <a
                            target="_blank"
                            href="https://shop.kalibradiet.com/it/web/kalibra/termini-e-condizioni-di-vendita"
                            style={{
                                color:"#68ABAD"
                            }}
                            rel="noreferrer"
                        >
                            condizioni di vendita
                        </a>
                    </label>
                </div>


                <div
                    className="row"
                    style={{
                        marginTop: "0px",
                        padding: "0px 24px"
                    }}
                >
                    <div
                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12"
                        style={{
                            textAlign: "center"
                        }}
                    >
                       <button
                            className={checkData() ? "btn-order" : "btn-grey"}
                            style={{
                                marginTop: "20px",
                                width: "100%"
                            }}
                            onClick={ordina}
                        >
                            Conferma ordine
                        </button>
                    </div>
                </div>
            </div>

        </div>
    )
}
