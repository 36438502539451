import React from "react";
import { useState, useEffect } from "react";
import { LogoTop } from "..";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export function SuccessRequestView() {

    const navigate = useNavigate();

    const {token} = useParams();

    function handleClose(){
        //window.open("https://wa.me/393288813933?text=Menu", '_self');
        navigate("/")
    }

    return (
        <div
            style={{
                backgroundColor: "#0F4651"
            }}
        >
            <div
                className="container"
                style={{
                    display:"flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    height: "110vh",
                    padding: "0px 24px",
                    backgroundColor: "#0F4651",
                    
                }}
            >
                <img
                    src={LogoTop}
                    alt=''
                    style={{
                        height: "48px",
                        display: "inline-block",
                        marginTop: "160px"
                    }}
                />
                <p
                    style={{
                        fontFamily: "Medium",
                        textAlign: "center",
                        fontSize:"30px",
                        color: "#68ABAD",
                        width: "160px",
                        marginTop: "32px"
                    }}
                >
                    Grazie!
                </p>
                <p
                    style={{
                        fontFamily: "Medium",
                        textAlign: "center",
                        fontSize:"22px",
                        color: "#FFFFFF",
                        width: "160px",
                        marginTop: "52px"
                    }}
                >
                    Il tuo ordine è stato inviato
                </p>
                <p
                    style={{
                        fontFamily: "Regular",
                        textAlign: "center",
                        fontSize:"16px",
                        color: "#FFFFFF",
                        marginTop: "20px",
                        maxWidth: "310px"
                    }}
                >
                    A breve sarai contattato da un membro del Team Ordini Kalibra®.
                </p>
            </div>
            <div
                onClick={handleClose}
                style={{
                    position: "fixed",
                    left: 0,
                    bottom: 0,
                    height: "110px",
                    width: "100%",
                    textAlign: "center",
                    backgroundColor: "#0F4651"
                }}
            >
                <p 
                    style={{
                        fontFamily: "SemiBold",
                        textAlign: "center",
                        fontSize:"20px",
                        color: "#68ABAD",
                        textDecoration: "underline",
                        cursor: "pointer"
                    }}
                >
                    Chiudi
                </p>
            </div>
        </div>
    )
}