import React, { useMemo } from "react";
import { useState, useEffect, useCallback } from "react";
import { useSearchParams } from 'react-router-dom';
import  ArrowBack from "@material-ui/icons/ArrowBack";
import Announcement from "@material-ui/icons/Announcement";
import Info from "@material-ui/icons/Info";
import {
    LogoTop,
} from "..";
import OrderCell from "../components/OrderCell";
import CategoryCell from "../components/CategoryCell";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useLocation, useParams } from "react-router-dom";
import { SERVICE_URL } from "..";
import Select from 'react-select';
import InfoModal from "../components/InfoModal";
import CartIcon from "@material-ui/icons/ShoppingCart";



export function HomeView(props) {

    const defaultList = 'CATALOGO'
    const defaultCategory = 'TUTTI GLI ALIMENTI'
    const catchAllCategory = 'TUTTI GLI ALIMENTI'

    const navigate = useNavigate();
    const token = props.token;//getCookie("kalibratoken")

    //const { token } = useParams();
    const { state } = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [articoliSelezionati, setArticoliSelezionati] = useState(state?.carrello || []);

    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState([])
    const [open, setOpen] = useState(localStorage.getItem("privacyModal") !== "true");

    const categories = useMemo(() => [catchAllCategory].concat(props.categories).map((value, index) => ({ value: index, label: value })), [props.categories])
    const selectedList = useMemo(() =>  searchParams.get('list') || defaultList, [searchParams])
    const selectedCategory = useMemo(() =>  categories.find(({ label }) => label === (searchParams.get('category') || defaultCategory)), [categories, searchParams])

    const fetchProducts = useCallback(async () => {
        if(!token){
            setIsLoading(false);
        }

        try {
            setIsLoading(true)
            const response = await axios.get(SERVICE_URL + '/start', {
                headers: {
                    'Authorization' : 'Bearer ' + token
                }
            })
            if (response.data) {
                if (selectedList === 'CATALOGO') {
                    setProducts(response.data.catalogo);
                } else {
                    setProducts(response.data.bestseller)
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [token, selectedList])

    const filteredProducts = useMemo(() => {
        if (selectedCategory.label === catchAllCategory) {
            return products
        }

        return products.filter((product) => product.CATEGORIA === selectedCategory.label)
    }, [products, selectedCategory])


    //const SERVICE_PATH = "http://192.168.1.207:4000/api"

    function ordina(){
        if(articoliSelezionati.length > 0){
            navigate("./carrello", {
                replace: false,
                state: {
                    carrello: articoliSelezionati
                }
            });
        }
    }

    function getCookie (name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    function sendRequest(){
        setOpen(true);
    }

    function onDetail(currentArticle) {
        navigate(`./productDetail?id=${currentArticle.CODICE}`, {
            replace: false,
            state: {
                carrello: articoliSelezionati,
                searchParams: searchParams ? { ...Object.fromEntries([...searchParams]) } : undefined
            }
        });
    }

    function getNumProdInCart(){
        let tot = 0;
        articoliSelezionati.forEach((elem, index) => {
            if(elem.qty){
                tot = parseInt(tot) + parseInt(elem.qty);
            }
        });
        return tot;
    }

    const handleClose = () => {
        setOpen(false);
        localStorage.setItem("privacyModal", true);
    }


    function handleListChange(list) {
        setSearchParams({ ...Object.fromEntries([...searchParams]), list, category: catchAllCategory })
    }

    function handleCategoryChange(category){
        setSearchParams({ ...Object.fromEntries([...searchParams]), category: category.label })
    }

    function setCarrello(articolo, qty){
        let tmpArticles = [...articoliSelezionati];
        if(qty === 0 ){
            tmpArticles = articoliSelezionati.filter((item) => item.CODICE !== articolo.CODICE);

        } else {
            let found = false;
            articoliSelezionati.forEach((elem,index) => {
                if(elem.CODICE === articolo.CODICE ){
                    tmpArticles[index].qty = tmpArticles[index].qty + qty;

                    found = true;
                }
            });
            if(!found){
                if(qty > 0 ){
                    let tmpArticle = articolo;
                    tmpArticle.qty = qty;
                    tmpArticles.push(tmpArticle)
                }
            }

        }
        setArticoliSelezionati(tmpArticles);
    }

    function goBack(){
        //window.open("https://wa.me/393288813933?text=Menu",'_self');
        localStorage.setItem("privacyModal", false);
    }

    useEffect(() => {
        setSearchParams(state?.searchParams || { list: defaultList, category: defaultCategory })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        fetchProducts()
    }, [fetchProducts])


    return (

        <div
            className="container"
            style={{
                marginTop: "0px",
                backgroundColor: "#FFFFFF",
                position: "relative"
            }}
        >

            <div
                className="row"
                style={{
                    padding: "0px 20px",
                    backgroundColor: "#0F4651",
                    height: "120px",
                    display: "flex",
                    alignItems: "center"
                }}
            >
                <div
                    onClick={goBack}
                    className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2"
                    style={{
                        marginTop: "16px"
                    }}
                >
                    <ArrowBack
                        style={{
                            width: "32px",
                            height: "32px",
                            color: "white"
                        }}
                    />
                </div>
                <div
                    className="col-lg-8 col-md-8 col-sm-8 col-xs-8 col-8"
                    style={{
                        marginTop: "16px",
                        textAlign: "center"
                    }}
                >
                    <img
                        src={LogoTop}
                        alt=''
                        style={{
                            height: "32px",
                            display: "inline-block"
                        }}
                    />
                </div>
                <div
                    className="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-2"
                    style={{
                        marginTop: "16px",
                        display: "flex",
                        justifyContent: "flex-end"
                    }}
                >
                    <div
                        onClick={sendRequest}
                        style={{
                            width: "40px",
                            height: "40px",
                            cursor:"pointer",
                            borderRadius: "20px",
                            justifyContent:"center",
                            alignItems:"center",
                            display:"flex"
                        }}
                    >
                        <Info
                            style={{
                                width: "32px",
                                height: "32px",
                                color: "#68ABAD",

                            }}
                        />
                    </div>

                </div>

            </div>
            <div
                style={{
                    textAlign: "center"
                }}
            >
                <div
                    className="row"
                    style={{

                        marginTop: "28px",
                        padding: "0px 20px",
                    }}
                >
                    <p
                        style={{
                            fontFamily: "SemiBold",
                            fontSize:"24px",
                            color: "#004750",
                            display: "inline-block",
                            width: "100%",
                            textAlign: "center !important",
                            marginBottom: "0px"
                        }}
                    >
                        Crea il tuo ordine
                    </p>

                </div>
                <div
                    className="row"
                    style={{
                        marginTop: "12px",
                        padding: "0px 20px",
                    }}
                >
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize:"16px",
                            color: "#808080",
                            display: "inline-block",
                            width: "100%",
                            textAlign: "center !important",
                            marginBottom: "0px"
                        }}
                    >
                        Clicca sugli alimenti e scopri tutte le info relative,
                        oppure aggiungili subito al tuo carrello!
                    </p>

                </div>

                <div
                    className="row"
                    style={{
                        marginTop: "24px",
                        padding: "0px 20px",
                    }}
                >
                    <div
                        className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6"
                    >
                        <button
                            className={selectedList === 'CATALOGO' ? "btn-light-outline" : "btn-light"}
                            onClick={() => handleListChange('BEST SELLERS')}
                        >
                            BEST SELLERS
                        </button>

                    </div>
                    <div
                        className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6"
                    >
                        <button
                            className={selectedList === 'CATALOGO' ? "btn-dark" : "btn-dark-outline"}
                            onClick={() => handleListChange('CATALOGO')}
                        >
                            CATALOGO
                        </button>

                    </div>
                </div>
                <div

                    style={{
                        marginTop: "24px",
                        padding: "0px 20px",
                    }}
                >
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize:"14px",
                            color: "#808080",
                            display: "inline-block",
                            width: "100%",
                            textAlign: "center !important",
                            marginBottom: "4px"
                        }}
                    >
                        Seleziona la categoria
                    </p>
                    <Select
                        value={selectedCategory}
                        options={categories}
                        isSearchable={false}
                        placeholder="Seleziona..."
                        onChange={handleCategoryChange}
                        isClearable={false}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                width:"100%",
                                height: "52px",
                                padding: "0px 4px",
                                borderRadius: "12px",
                                border: "2px solid #D9D9D9",
                                backgroundColor: "#FFFFFF",
                                color: "red",
                                fontFamily: "Medium",
                                fontSize: "14px",
                                textAlign: "left"
                            })
                        }}

                    />
                </div>

                <div
                    style={{
                        backgroundColor: "#F0F0F0",
                        height: "2px",
                        marginTop: "18px",
                        padding: "0px 20px",
                    }}
                />
            </div>

            <div
                style={{
                    position:"relative",
                    minHeight:"300px"
                }}
            >
                <div
                    style={{
                        display:`${isLoading ? "flex" : "none"}`,
                        justifyContent:"center",
                        alignItems:"start",
                        height: "100vh",
                        width: "100%",
                        position: "absolute",
                        backgroundColor: "rgba(255, 255, 255, 1)",
                        zIndex: "100"
                    }}
                    >
                    <dotlottie-player src="https://lottie.host/90a4cd86-6db1-4a26-a699-a36fd63d1fce/tEBIPFUn7o.json" mode="normal" background="transparent" speed="1" style={{width: "300px", height: "300px", display: "block"}} direction="1" loop autoplay></dotlottie-player>
                </div>

                {filteredProducts.length === 0 ? (
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize:"16px",
                            color: "#808080",
                            display: "inline-block",
                            width: "100%",
                            marginBottom: "0px",
                            padding: "0px 24px",
                            textAlign: "center",
                            marginTop: "64px"
                        }}
                    >
                        Non ci sono prodotti disponibili nella categoria selezionata.
                    </p>
                ) : (
                    filteredProducts.map((articolo) => (
                        <div>
                            <OrderCell key={articolo.CODICE} articolo={articolo} setCarrello={setCarrello} onDetail={onDetail}/>
                        </div>
                    ))
                )}
            </div>
            <div
                style={{
                    marginBottom:"150px",
                    padding: "0px 20px",
                }}
            />


            <InfoModal isOpen={open} onClose={handleClose}>
                <>
                    <p
                        style={{
                            fontFamily: "Medium",
                            fontSize:"22px",
                            color: "#004750",
                            textAlign: "center !important",
                            marginBottom: "24px"
                        }}
                    >
                        Ti ricordiamo che...
                    </p>
                    <p
                        style={{
                            fontFamily: "Regular",
                            fontSize:"16px",
                            color: "#808080",
                            textAlign: "center !important",
                            marginBottom: "0px"
                        }}
                    >
                        Per effettuare un ordine di alimenti Kalibra®, è sempre&nbsp;
                        <span
                            style={{
                                fontFamily: "Semibold",
                            }}
                        >
                            necessario essere in possesso di una prescrizione valida rilasciata dal tuo Esperto in Nutrizione.
                        </span>
                        <br/>
                        <br/>
                        Se non hai ancora effettuato la visita nutrizionale,&nbsp;
                        <span
                            style={{
                                fontFamily: "Semibold",
                            }}
                        >
                            contattaci per conoscere gli Esperti più vicini a te.
                        </span>
                    </p>

                    <button
                        className="btn-light"
                        onClick={handleClose}
                        style={{
                            marginTop:"24px",
                            zIndex: 1000
                        }}
                    >
                        HO CAPITO
                    </button>

                </>
            </InfoModal>




            <div
                className="footer"
                style={{
                    position: "fixed",
                    bottom: 0,
                    left:0,
                    height: "110px",
                    borderRadius: "24px 24px 0px 0px",
                    borderTop: "2px solid #F0F0F0",
                    boxShadow: "0px -5px 25px 0px rgba(0, 0, 0, 0.10)",
                    width: "100%",
                    backgroundColor:"#FFFFFF"
                }}
            >
                <div
                    className="row"
                    style={{
                        marginTop: "28px",
                        padding: "0px 24px"
                    }}
                >
                    <div
                        className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-4"
                    >
                        <p
                            style={{
                                fontFamily: "Semibold",
                                fontSize:"12px",
                                color: "#004750",
                                display: "inline-block",
                                width: "100%",
                                textAlign: "center !important",
                                marginBottom: "0px"
                            }}
                        >
                            TOT ORDINE:
                        </p>
                        <p
                            style={{
                                fontFamily: "Regular",
                                fontSize:"16px",
                                color: "#808080",
                                display: "inline-block",
                                width: "100%",
                                textAlign: "center !important",
                                marginBottom: "0px"
                            }}
                        >
                            {getNumProdInCart()} articoli
                        </p>
                    </div>
                    <div
                        className="col-lg-8 col-md-8 col-sm-8 col-xs-8 col-8"
                        style={{
                            textAlign: "end"
                        }}
                    >
                        <button
                            style={{fontSize:"15px"}}
                            className={articoliSelezionati.length > 0 ? "btn-order" : "btn-grey"}
                            onClick={ordina}
                        >
                            Vai al carrello
                            <CartIcon
                                style={{
                                    width: "28px",
                                    height: "28px",
                                    color: "white", marginLeft: "8px"
                                }}
                            />
                        </button>
                    </div>

                </div>

            </div>



        </div>
    )
}
