import React from "react";
import { LogoTop } from "..";
import {  useNavigate } from "react-router-dom";

export function ErrorView() {

    const navigate = useNavigate();

    function handleClose(){
        navigate("/", {
            replace: true,
        });
    }

    return (
        <div
            style={{
                display:"flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
                height: "100vh",
                backgroundColor: "#0F4651",
            }}
        >
                <p style={{
                        margin: "0 16px",
                        fontFamily: "Medium",
                        textAlign: "center",
                        fontSize:"30px",
                        color: "#68ABAD",
                        width: "160px",
                    }}
                >
                <img
                    src={LogoTop}
                    alt=''
                    style={{
                        height: "48px",
                        display: "inline-block"
                    }}
                />
                    Attenzione!
                </p>
                <p
                    style={{
                        margin: "0 16px",
                        fontFamily: "Medium",
                        textAlign: "center",
                        fontSize:"20px",
                        color: "#FFFFFF",
                        width: "180px",
                    }}
                >
                    Qualcosa è andato storto...
                </p>
                <p 
                    onClick={handleClose}
                    style={{
                        margin: "0 16px",
                        fontFamily: "SemiBold",
                        textAlign: "center",
                        fontSize:"18px",
                        color: "#68ABAD",
                        textDecoration: "underline"
                    }}
                >
                    Chiudi
                </p>
        </div>
    )
}